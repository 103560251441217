import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { FAQ } from "../../../constants/faqs";
import useStyles from "./styles";

interface FAQItemProps {
  faq: FAQ;
}

function FAQItem({ faq }: FAQItemProps) {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      elevation={0}
      square
      expanded={isExpanded}
      className={isExpanded ? classes.expandedFAQItem : classes.FAQItem}
      onChange={(event, isExpanded) => setIsExpanded(isExpanded)}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className={isExpanded ? classes.collapseIcon : classes.expandIcon}
          />
        }
      >
        <Typography variant="h5" className={classes.faqQuestionText}>
          {faq.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h6" className={classes.faqAnswerText}>
          {faq.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default FAQItem;
