import React from "react";
import useStyles from "./styles";
import HowItWorksItem from "./how-works-item";
import howItWorksItems from "../../constants/how-it-works-items";

function HowItWorks() {
  const classes = useStyles();

  return (
    <div className={classes.hotItWorks}>
      {howItWorksItems.map((howItWorksItem, index: number) => (
        <div className={classes.howItWorksItemContainer} key={index}>
          <HowItWorksItem
            image={howItWorksItem.image}
            text={howItWorksItem.text}
            highlightedText={howItWorksItem.highlightedText}
          />
        </div>
      ))}
    </div>
  );
}

export default HowItWorks;
