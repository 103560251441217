export interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: "O valor da mensalidade muda ao longo da assinatura? ",
    answer: "Não! A mensalidade é fixa do início ao fim. O valor pode mudar de acordo com o mês em que você entra, já que é possível entrar até o mês 11, mas o valor se repete pelo número de meses restantes até o mês 12.",
  },
  {
    question: "E se eu quiser cancelar?",
    answer: "Você pode cancelar a qualquer momento, é só solicitar com 40 dias de antecedência. A Box na sequência será faturada, e então faremos o cancelamento. Solicite enviando email para contato@smartbabybox.com.br com o assunto \"quero cancelar minha assinatura\" e nome e email cadastrados na plataforma de pagamento.",
  },
  {
    question: "Meu bebê já tem dois meses, mas quero receber a Box 1, como faço? ",
    answer: "Você pode assinar a partir da Box 2 e marcar no formulário que você deseja comprar também a Box 1. Ela sairá pelo valor da mensalidade da assinatura contratada e entraremos em contato para que você decida se prefere pagar via pix ou cartão de crédito. Com a confirmação do pagamento, enviamos juntas as Boxes 1 e 2, e a partir do próximo mês a assinatura segue normal, recebendo as demais Boxes na sequência.",
  },
  {
    question: "Meu bebê tem 9 meses, quero entrar na assinatura mas também quero algumas Boxes anteriores, como a 4 e a 6, como posso fazer?",
    answer: "Caso você queira Boxes imediatamente anteriores, elas saem pelo valor da mensalidade da assinatura, com algumas particularidades, como por exemplo quando o valor da mensalidade muda. Vou explicar: Se assinando a partir da Box 9 o valor é A, e a partir da Box 8 o valor é B e você quiser assinar a partir da 9, mas quiser que a gente envie ao mesmo tempo também a Box 8, calculamos 5xB (porque são 5 mensalidades de 8 a 12 meses) - 4xA, e a Box 8 sai pela diferença. Parece uma conta difícil, mas é tudo pensado para que você não saia prejudicado caso queira as Boxes anteriores e tenha as melhores condições para adquirir. Para adquirir Boxes não imediatamente anteriores, o assinante tem desconto de cerca de 15% sobre o valor da Box avulsa. Consulte a gente por email em contato@smartbabybox.com.br ou por direct no Insta que o nosso atendimento lhe explicará as condições especiais para assinantes!",
  },
  {
    question: "Posso mudar de modalidade de assinatura?",
    answer: "Se você assinou a Assinatura Completa desde a Box 1, pode mudar para a Básica após a Box 6, pelo menos. Para sair da Básica para a Completa, você pode fazê-lo a qualquer momento, porém isso pode envolver alguma mudança no valor da mensalidade a depender do mês em que a mudança acontece. Entre em contato com contato@smartbabybox.com.br e solicite a mudança que lhe explicaremos as condições para o seu caso."
  },
  {
    question: "Comprei uma Box e não gostei. Como devolver? ",
    answer: "Segundo a lei para compras online, você pode devolver até 7 dias após o recebimento da compra. Mande email solicitando a devolução para contato@smartbabybox.com.br. Daí encontraremos o ponto de devolução mais perto da sua casa e enviaremos as orientações para a postagem de volta. Para isso você precisará guardar a embalagem e enviar a Box do jeito que recebeu, na mesma caixa."
  }
]

export default faqs;