import React from "react";
import faqs, { FAQ } from "../../constants/faqs";
import FAQItem from "./FAQ-item/FAQItem";
import useStyles from "./styles";

function FAQContainer() {
  const classes = useStyles();
  return (
    <div className={classes.FAQContainer}>
      {faqs.map((faq: FAQ, index: number) => (
        <div className={classes.FAQItemContainer} key={index}>
          <FAQItem faq={faq} />
        </div>
      ))}
    </div>
  );
}

export default FAQContainer;
