import howItWorksImage1 from "../assets/images/how-it-works-1.png";
import howItWorksImage2 from "../assets/images/how-it-works-2.png";
import howItWorksImage3 from "../assets/images/how-it-works-3.png";

interface HowItWorksItem {
  image: string;
  text: string;
  highlightedText: string;
}

const howItWorksItems: HowItWorksItem[] = [
  {
    image: howItWorksImage1,
    text: "Escolha o plano ideal para você e a partir de qual Box você deseja receber",
    highlightedText: "Box",
  },
  {
    image: howItWorksImage2,
    text: "Assine já, cadastrando um cartão de crédito para pagamento automático a cada mês",
    highlightedText: "cartão",
  },
  {
    image: howItWorksImage3,
    text: "Receba em sua casa brinquedos adequados ao desenvolvimento do seu bebê!",
    highlightedText: "brinquedos adequados",
  },
];

export default howItWorksItems;