import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  hotItWorks: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    }
  },
  howItWorksItemContainer: {
    marginLeft: 24,
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
      marginLeft: 0,
      marginRight: 0,
    }
  }
}));


export default useStyles;