
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  FAQItem: {
    backgroundColor: theme.palette.secondary.main,
    border: "4px solid #F1A2B1",
    color: theme.palette.text.primary,
    paddingLeft: 48,
    paddingRight: 48,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  expandedFAQItem: {
    backgroundColor: "#FFF",
    color: theme.palette.secondary.main,
    paddingLeft: 48,
    paddingRight: 48,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  expandIcon: {
    color: theme.palette.text.primary
  },
  collapseIcon: {
    color: theme.palette.secondary.main
  },
  faqQuestionText: {
    fontWeight: 800,
    fontFamily: 'Dosis, Roboto',

  },
  faqAnswerText: {
    color: "#333762",
    fontFamily: 'Dosis, Roboto',
    fontWeight: 500,
  }
}));

export default useStyles;