import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  FAQContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  FAQItemContainer: {
    marginTop: 26
  }
}));

export default useStyles;