import { Paper, useTheme } from "@material-ui/core";
import React from "react";
import HighlightedText from "../../highlighted-text/HighlightedText";
import useStyles from "./styles";

interface HowWorksItemProps {
  image: string;
  text: string;
  highlightedText: string;
}

function HowWorksItem({ image, text, highlightedText }: HowWorksItemProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper elevation={0} className={classes.howWorksCard}>
      <img src={image} alt="Como funciona" />
      <div className={classes.howItWorksTextArea}>
        <HighlightedText
          text={text}
          highlightedText={highlightedText}
          highlightColor={theme.palette.secondary.main}
          styles={{ textAlign: "center" }}
        />
      </div>
    </Paper>
  );
}

export default HowWorksItem;
