import { useTheme } from "@material-ui/core";
import React, { useEffect, useRef } from "react";

interface HighlightedTextProps {
  text: string;
  highlightedText: string;
  highlightColor: string;
  fontSize?: number;
  textColor?: string;
  styles?: any;
}

function HighlightedText({
  text,
  highlightedText,
  fontSize = 18,
  highlightColor,
  textColor = "#333762",
  styles,
}: HighlightedTextProps) {
  const textRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.innerHTML = `${text.replace(
        highlightedText,
        `<span style="color: ${highlightColor}; font-weight: 700">${highlightedText}</span>`
      )}`;
    }
  }, [text, highlightedText]);

  return (
    <div
      ref={textRef}
      style={{
        fontSize,
        color: textColor,
        fontWeight: 500,
        ...styles,
      }}
    />
  );
}

export default HighlightedText;
