import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: any) => ({
  howWorksCard: {
    width: 280,
    borderRadius: 10,
    height: 360,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
  },
  howItWorksTextArea: {
    marginTop: 24,
  }
}));

export default useStyles;